import "./HomeSignup.scss";
import { Link } from "react-router-dom";

export default function HomeSignup() {
  return (
    <div className="home-signup">
      <h1 className="home-signup__question">
        Want to contribute to our guides?
      </h1>
      <p className="home-signup__text">
        Signup to post articles*{" "}
        <Link to="/signup" className="home-signup__button">
          Signup
        </Link>
      </p>
    </div>
  );
}
