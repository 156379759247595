import "./App.scss";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// importing pages
import CategoriesPage from "./pages/CategoriesPage/CategoriesPage";
import ArticlesByCategoryPage from "./pages/ArticlesByCategoryPage/ArticlesByCategoryPage";
import ArticlesPage from "./pages/ArticlesPage/ArticlesPage";
import ArticlePage from "./pages/ArticlePage/ArticlePage";
import HomePage from "./pages/HomePage/HomePage";
import PostArticlePage from "./pages/PostArticlePage/PostArticlePage";
import Footer from "./components/Footer/Footer";
import NotFound from "./components/NotFound/NotFound";

// importing components
import Header from "./components/Header/Header";
import LoginPage from "./pages/LoginPage/LoginPage";
import SignupPage from "./pages/SignupPage/SignupPage";

function App() {
  return (
    <BrowserRouter>
      <div className="home">
        <Header />

        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/categories" element={<CategoriesPage />} />
          <Route path="/categories/:id" element={<ArticlesByCategoryPage />} />
          <Route path="/articles" element={<ArticlesPage />} />
          <Route path="/article/:id" element={<ArticlePage />} />

          <Route path="/login" element={<LoginPage />} />
          <Route path="/signup" element={<SignupPage />} />
          <Route path="/accounts/:id" element={<PostArticlePage />} />
          <Route path="*" element={<NotFound />} />
        </Routes>

        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
