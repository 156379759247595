import Login from "../../components/Login/Login";
import LoginSignupImage from "../../components/LoginSignupImage/LoginSignupImage";
import { useLayoutEffect } from "react";

import "./LoginPage.scss";

export default function LoginPage() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div className="login-page">
      <LoginSignupImage />
      <Login />
    </div>
  );
}
