import "./Footer.scss";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div className="footer">
      <div className="footer__container">
        <div className="footer__logo-container">
          <Link to="/" className="footer__logo" />
          <Link to="/" className="footer__text">
            WiseMe
          </Link>
        </div>
        <ul className="footer__list">
          <li>
            <Link to="/categories" className="footer__list-link">
              Guides
            </Link>
          </li>
          <li>
            <Link to="/articles" className="footer__list-link">
              Articles
            </Link>
          </li>
          <li>
            <Link to="/login" className="footer__list-link">
              Login
            </Link>
          </li>
          <li>
            <Link to="/signup" className="footer__list-link">
              Signup
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
}
