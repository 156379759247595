import "./ArticlesListByCategoryId.scss";
import { useState, useEffect } from "react";
import { getArticlesByCategoryId } from "../../utils/api";
import Tile from "../Tile/Tile";
import Loading from "../../components/Loading/Loading";

export default function ArticlesListByCategoryId({ id }) {
  const [articles, setArticles] = useState([]);
  const [query, setQuery] = useState("");

  const filteredArticles = articles.filter((article) => {
    return article.title.toLowerCase().includes(query.toLowerCase());
  });

  useEffect(() => {
    getArticlesByCategoryId(id)
      .then((response) => {
        setArticles(response.data);
      })
      .catch((error) => {
        return console.log(error);
      });
  }, [id]);

  if (!articles) {
    return <Loading />;
  }

  return (
    <>
      <div className="articles-by-category">
        <div className="articles-by-category__top-bar">
          <input
            className="articles-by-category__search"
            placeholder="Search articles..."
            value={query}
            type="search"
            onChange={(e) => setQuery(e.target.value)}
          />
        </div>
        <div className="articles-by-category__list">
          {filteredArticles.map((article) => (
            <Tile
              title={article.title}
              imageUrl={article.image_url}
              id={article.id}
              key={article.id}
              isArticle={true}
            />
          ))}
        </div>
      </div>
    </>
  );
}
