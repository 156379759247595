import "./Signup.scss";
import logo from "../../assets/images/logo.png";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { signup } from "../../utils/api";

export default function Signup() {
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [nameValidation, setNameValidation] = useState(true);
  const [usernameValidation, setUsernameValidation] = useState(true);
  const [passwordValidation, setPasswordValidation] = useState(true);

  const navigate = useNavigate();

  function handleOnSubmit(e) {
    e.preventDefault();

    if (name === "") {
      setNameValidation(false);
    } else {
      setNameValidation(true);
    }

    if (username === "") {
      setUsernameValidation(false);
    } else {
      setUsernameValidation(true);
    }

    if (password === "") {
      setPasswordValidation(false);
    } else {
      setPasswordValidation(true);
    }

    if (name !== "" && username !== "" && password !== "") {
      const signupDetails = {
        name: name,
        email: username,
        password: password,
      };

      signup(signupDetails)
        .then((user) => {
          if (user.data === "Created") {
            navigate("/login");
            alert("Account created");
          } else {
            alert("The user already exists");
          }
        })
        .catch(() => {
          alert(
            "Unfortunately, the sign up process failed, please try again later."
          );
        });
    }
  }
  return (
    <form className="signup" onSubmit={handleOnSubmit}>
      <img src={logo} alt="logo" className="signup__logo" />
      <h1 className="signup__title">WiseMe</h1>
      <p className="signup__text">Welcome!</p>
      <label htmlFor="nameText" className="signup__label">
        Name
      </label>
      <input
        type="text"
        className={
          nameValidation
            ? "signup__input"
            : "signup__input signup__input--error"
        }
        onChange={(event) => {
          setName(event.target.value);
        }}
        name="nameText"
        placeholder="Enter your name"
      />
      <p
        className={
          usernameValidation
            ? "signup__validation-message"
            : "signup__validation-message signup__validation-message--visible"
        }
      >
        Please enter your name
      </p>
      <label htmlFor="usernameText" className="signup__label">
        Username / Email
      </label>
      <input
        type="text"
        className={
          usernameValidation
            ? "signup__input"
            : "signup__input signup__input--error"
        }
        name="usernameText"
        onChange={(event) => {
          setUsername(event.target.value);
        }}
        placeholder="Enter your username"
      />
      <p
        className={
          passwordValidation
            ? "login__validation-message"
            : "login__validation-message login__validation-message--visible"
        }
      >
        Please enter your email
      </p>
      <label htmlFor="passwordText" className="signup__label">
        Password
      </label>
      <input
        type="password"
        className={
          passwordValidation
            ? "signup__input"
            : "signup__input signup__input--error"
        }
        name="passwordText"
        onChange={(event) => {
          setPassword(event.target.value);
        }}
        placeholder="Enter your password"
      />
      <p
        className={
          passwordValidation
            ? "signup__validation-message"
            : "signup__validation-message signup__validation-message--visible"
        }
      >
        Please enter your new password
      </p>
      <input type="submit" className="signup__button" value="Sign up" />
    </form>
  );
}
