import "./ArticlesPage.scss";
import ArticlesList from "../../components/ArticlesList/ArticlesList";
import PageTitle from "../../components/PageTitle/PageTitle";

export default function ArticlesPage() {
  return (
    <>
      <PageTitle title="List of All Articles" />
      <ArticlesList />
    </>
  );
}
