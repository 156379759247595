import "./PostArticlePage.scss";
import RichTextEditor from "../../components/RichTextEditor/RichTextEditor";
// import { useState } from "react";

export default function PostArticlePage() {
  return (
    <>
      <RichTextEditor />
    </>
  );
}
