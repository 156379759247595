import "./Login.scss";
import logo from "../../assets/images/logo.png";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { login } from "../../utils/api";

export default function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [usernameValidation, setUsernameValidation] = useState(true);
  const [passwordValidation, setPasswordValidation] = useState(true);

  const navigate = useNavigate();

  function handleOnSubmit(e) {
    e.preventDefault();

    if (username === "") {
      setUsernameValidation(false);
    } else {
      setUsernameValidation(true);
    }

    if (password === "") {
      setPasswordValidation(false);
    } else {
      setPasswordValidation(true);
    }

    if (username !== "" && password !== "") {
      const loginCredentials = {
        email: username,
        password: password,
      };

      login(loginCredentials)
        .then((user) => {
          if (!!user.data.id) {
            navigate("/accounts/" + user.data.id);
          }
        })
        .catch(() => {
          alert("Unfortunately, the login failed, please try again.");
        });
    }
  }

  return (
    <form className="login" onSubmit={handleOnSubmit}>
      <img src={logo} alt="logo" className="login__logo" />
      <h1 className="login__title">WiseMe</h1>
      <p className="login__text">Welcome back</p>
      <label htmlFor="username-text" className="login__label">
        Username / Email
      </label>
      <input
        type="text"
        className={
          usernameValidation
            ? "login__input"
            : "login__input login__input--error"
        }
        name="usernameText"
        onChange={(event) => {
          setUsername(event.target.value);
        }}
        placeholder="Enter your username"
      />
      <p
        className={
          usernameValidation
            ? "login__validation-message"
            : "login__validation-message login__validation-message--visible"
        }
      >
        Please enter your email
      </p>
      <label htmlFor="passwordText" className="login__label">
        Password
      </label>
      <input
        type="password"
        className={
          passwordValidation
            ? "login__input"
            : "login__input login__input--error"
        }
        name="password-text"
        onChange={(event) => {
          setPassword(event.target.value);
        }}
        placeholder="Enter your password"
      />
      <p
        className={
          passwordValidation
            ? "login__validation-message"
            : "login__validation-message login__validation-message--visible"
        }
      >
        Please enter your password
      </p>
      <input type="submit" className="login__button" value="Login" />

      <Link className="login__sign-up-link" to="/signup">
        Don't have an account, get one! Click here!!
      </Link>
    </form>
  );
}
