import { getArticle, timeAgo } from "../../utils/api";
import "./ArticlePage.scss";
import { useParams } from "react-router";
import { useEffect, useState } from "react";
import Loading from "../../components/Loading/Loading";

export default function ArticlePage() {
  const { id } = useParams();

  const [article, setArticle] = useState(null);

  useEffect(() => {
    getArticle(id)
      .then((response) => {
        setArticle(response.data);
      })
      .catch((error) => {
        return console.log(error);
      });
  }, [id]);
  if (!article) {
    return <Loading />;
  }

  return (
    <article className="article">
      <img
        src={article.image_url}
        alt={article.title}
        className="article__image"
      />
      <h1 className="article__title">{article.title}</h1>
      <h3 className="article__metadata">
        Written by: {article.user_name}
        {"   .   "}
        {timeAgo(new Date(article.created_at))}
      </h3>

      <p
        className="article__body"
        dangerouslySetInnerHTML={{ __html: article.body }}
      ></p>
    </article>
  );
}
