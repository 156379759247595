import Signup from "../../components/Signup/Signup";
import LoginSignupImage from "../../components/LoginSignupImage/LoginSignupImage";
import { useLayoutEffect } from "react";

import "./SignupPage.scss";

export default function SignupPage() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div className="signup-page">
      <LoginSignupImage />
      <Signup />
    </div>
  );
}
