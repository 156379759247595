import "./ArticlesList.scss";
import { useState, useEffect } from "react";
import { getArticles } from "../../utils/api";
import Tile from "../Tile/Tile";
import Loading from "../Loading/Loading";

export default function ArticlesList() {
  const [articles, setArticles] = useState([]);
  const [query, setQuery] = useState("");

  const filteredArticles = articles.filter((article) => {
    return article.title.toLowerCase().includes(query.toLowerCase());
  });

  useEffect(() => {
    getArticles()
      .then((response) => {
        setArticles(response.data);
      })
      .catch((error) => {
        return console.log(error);
      });
  }, []);
  if (!articles) {
    return <Loading />;
  }
  return (
    <>
      <div className="articles">
        <div className="articles__top-bar">
          <input
            className="articles__search"
            placeholder="Search articles..."
            value={query}
            type="search"
            onChange={(e) => setQuery(e.target.value)}
          />
        </div>
        <div className="articles__list">
          {filteredArticles.map((article) => (
            <Tile
              title={article.title}
              imageUrl={article.image_url}
              id={article.id}
              key={article.id}
              isArticle={true}
            />
          ))}
        </div>
      </div>
    </>
  );
}
