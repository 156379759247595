import "./Tile.scss";
import { Link } from "react-router-dom";

export default function Tile({
  title,
  imageUrl,
  id,
  category,
  created,
  isArticle,
  isForHome,
}) {
  if (isForHome)
    return (
      <Link className="tile--full-width" to={`/article/${id}`}>
        <img
          className="tile__image--full-width"
          src={imageUrl}
          alt="category"
        />
        <h1 className="tile__title tile__title--full-line">{title}</h1>
        <h1 className="tile__category">{category}</h1>
        <h3 className="tile__date">{created}</h3>
      </Link>
    );
  if (!isArticle) {
    return (
      <Link className="tile" to={`/categories/${id}`}>
        <img className="tile__image" src={imageUrl} alt="category" />
        <h1 className="tile__title">{title}</h1>
      </Link>
    );
  } else {
    return (
      <Link className="tile" to={`/article/${id}`}>
        <img className="tile__image" src={imageUrl} alt="category" />
        <h1 className="tile__title">{title}</h1>
      </Link>
    );
  }
}
