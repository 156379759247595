import ArticlesListByCategoryId from "../../components/ArticlesListByCategoryId/ArticlesListByCategoryId";
import PageTitle from "../../components/PageTitle/PageTitle";
import "./ArticlesByCategoryPage.scss";
import { useParams } from "react-router";

export default function CategoriesPage() {
  const { id } = useParams();

  return (
    <>
      <PageTitle title="Articles of Selected Guide" />
      <ArticlesListByCategoryId id={id} isArticle={true} />
    </>
  );
}
