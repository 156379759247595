import "./LatestArticlesList.scss";
import { useState, useEffect } from "react";
import { getLatestArticles } from "../../utils/api";
import Tile from "../Tile/Tile";
import Loading from "../Loading/Loading";
import { timeAgo } from "../../utils/api";

export default function LatestArticlesList() {
  const [articles, setArticles] = useState([]);
  const [article, setArticle] = useState([]);

  useEffect(() => {
    getLatestArticles()
      .then((response) => {
        setArticles(
          response.data.filter((article, index) => {
            if (index !== 0) return article;
          })
        );
        setArticle(response.data[0]);
      })
      .catch((error) => {
        return console.log(error);
      });
  }, []);
  if (!articles) {
    return <Loading />;
  }
  return (
    <div className="latest-articles">
      <div className="latest-articles__container">
        <div className="latest-articles__left">
          <div className="latest-articles__title">Latest Articles</div>
          <div className="latest-articles__tile">
            <Tile
              title={article.title}
              imageUrl={article.image_url}
              id={article.id}
              category={article.category}
              created={timeAgo(new Date(article.created_at))}
              key={article.id}
              isArticle={true}
              isForHome={true}
            />
          </div>
        </div>
        <div className="latest-articles__right">
          {articles.map((sideArticle) => (
            <Tile
              title={sideArticle.title}
              imageUrl={sideArticle.image_url}
              id={sideArticle.id}
              category={sideArticle.category}
              created={timeAgo(new Date(sideArticle.created_at))}
              key={sideArticle.id}
              isArticle={true}
              isForHome={true}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
