import axios from "axios";

axios.defaults.baseURL =
  process.env.REACT_APP_API_URL || "http://localhost:8080";

export const getCategories = () => axios.get("/categories");
export const getArticlesByCategoryId = (id) => axios.get(`/categories/${id}`);
export const getArticles = () => axios.get("/articles");
export const getLatestArticles = () => axios.get("/articles/latest");
export const getArticle = (id) => axios.get(`/articles/single/${id}`);
export const login = (loginCredentials) =>
  axios.post("/accounts/login", loginCredentials);

export const signup = (signupDetails) =>
  axios.post("/accounts/signup", signupDetails);

export const postArticle = (newArticle) =>
  axios.post("/articles", newArticle, {
    headers: { "Content-Type": "multipart/form-data" },
  });

export const timeAgo = (date) => {
  const seconds = Math.floor((new Date() - date) / 1000);

  let interval = Math.floor(seconds / 31536000);
  if (interval > 1) {
    return interval + " years ago";
  }

  interval = Math.floor(seconds / 2592000);
  if (interval > 1) {
    return interval + " months ago";
  }

  interval = Math.floor(seconds / 86400);
  if (interval > 1) {
    return interval + " days ago";
  }

  interval = Math.floor(seconds / 3600);
  if (interval > 1) {
    return interval + " hours ago";
  }

  interval = Math.floor(seconds / 60);
  if (interval > 1) {
    return interval + " minutes ago";
  }

  if (seconds < 10) return "just now";

  return Math.floor(seconds) + " seconds ago";
};
