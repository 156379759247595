import CategoriesList from "../../components/CategoriesList/CategoriesList";
import PageTitle from "../../components/PageTitle/PageTitle";
import "./CategoriesPage.scss";

export default function CategoriesPage() {
  return (
    <>
      <PageTitle title="Guides by Communities" />
      <CategoriesList />
    </>
  );
}
