import "./CategoriesList.scss";
import { useState, useEffect } from "react";
import { getCategories } from "../../utils/api";
import Tile from "../Tile/Tile";
import Loading from "../Loading/Loading";

export default function CategoriesList() {
  const [categories, setCategories] = useState([]);
  const [query, setQuery] = useState("");

  const filteredCategories = categories.filter((category) => {
    return category.title.toLowerCase().includes(query.toLowerCase());
  });

  useEffect(() => {
    getCategories()
      .then((response) => {
        setCategories(response.data);
      })
      .catch((error) => {
        return console.log(error);
      });
  }, []);
  if (!categories) {
    return <Loading />;
  }
  return (
    <>
      <div className="categories">
        <div className="categories__top-bar">
          <input
            className="categories__search"
            placeholder="Search categories..."
            value={query}
            type="search"
            onChange={(e) => setQuery(e.target.value)}
          />
        </div>
        <div className="categories__list">
          {filteredCategories.map((category) => (
            <Tile
              title={category.title}
              imageUrl={category.image_url}
              id={category.id}
              key={category.id}
              isArticle={false}
            />
          ))}
        </div>
      </div>
    </>
  );
}
