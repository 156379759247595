import { NavLink, Link } from "react-router-dom";
import "./Header.scss";

export default function Header() {
  const navLinkActive = ({ isActive }) => {
    if (isActive) {
      return "header__nav-link header__nav-link--active";
    } else {
      return "header__nav-link";
    }
  };

  return (
    <div className="header">
      <div className="header__container">
        <div className="header__logo-container">
          <Link to="/" className="header__logo" />
          <Link to="/" className="header__text">
            WiseMe
          </Link>
        </div>
        <div className="header__nav-bar">
          <NavLink className={navLinkActive} to="/">
            Home
          </NavLink>
          <NavLink className={navLinkActive} to="/categories">
            Guides
          </NavLink>
          <NavLink className={navLinkActive} to="/articles">
            Articles
          </NavLink>
          <NavLink className={navLinkActive} to="/login">
            Login / Signup
          </NavLink>
        </div>
      </div>
    </div>
  );
}
