import "./HomePage.scss";
import Hero from "../../components/Hero/Hero";
import About from "../../components/About/About";
import LatestArticlesList from "../../components/LatestArticlesList/LatestArticlesList";
import Video from "../../components/video/Video";
import HomeSignup from "../../components/HomeSignup/HomeSignup";

export default function HomePage() {
  return (
    <>
      <Hero />
      <About />
      <LatestArticlesList />
      <Video />
      <HomeSignup />
    </>
  );
}
