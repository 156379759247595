import "./About.scss";

export default function About() {
  return (
    <div className="about">
      <div className="about__title">WiseMe - Communities Together</div>
      <div className="about__paragraph">
        WiseMe aspires to be a safeplace for communities to share and guide
        anyone who seeks to get information about how to navigate life in Canada
        to take advantage of the resources available and the help that the whole
        community can provide. We start with a curated list of guides consisting
        of articles and we will move to providing a complete solution for
        events, social opportunities with other members of those communities,
        and event property listings. It's a continuous work that will get better
        with the feedback from every user of the site. Learn and contribute and
        enjoy your time in Canada.
      </div>
    </div>
  );
}
