import "./Hero.scss";
// import back from "../../assets/images/back.png";
import heroImage from "../../assets/images/community.svg";

export default function Hero() {
  return (
    <div className="hero">
      <div className="hero__container">
        <div className="hero__text">
          <i>Individually,</i> we are one <strong>drop.</strong>{" "}
          <i>Together,</i> we are an <strong>ocean.</strong>"{" "}
          <strong>- Ryunosuke Satoro</strong>
        </div>
        <div className="hero__image-container">
          <img src={heroImage} alt="" className="hero__image" />
        </div>
      </div>
    </div>
  );
}
